'use strict';

window.console || (window.console = {
	log: function log() {}
});
var THERMOS = window.THERMOS || null;
var cssua = window.cssua || null;

(function ($) {
	var htmlHasClass = function htmlHasClass(_class) {
		return $('html').hasClass(_class);
	};
	var siteName = 'thermos';
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '300';

	THERMOS = {
		va: {
			pathname: location.pathname,
			isEnglish: /^\/english\//.test(location.pathname) ? true : false,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function (e) {
				$(e.currentTarget).toggleClass(a);
				$(e.currentTarget).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (THERMOS.va.window.width < THERMOS.va.device.sp) {
						if (THERMOS.va.pathname === '/company/history/corporate_chronology.html') {
							pos -= 100;
						} else {
							pos -= 60;
						}
					} else {
						pos -= 90;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash) {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (THERMOS.va.window.width < THERMOS.va.device.sp) {
							pos -= 60;
						} else {
							pos -= 90;
						}
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				});
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			if ($pagetop.length) {
				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var $footer = $('.footer');
					var footerPos = $footer.offset().top + $footer.height();
					var thisScroll = $this.scrollTop();
					var pagetopPos = $this.height() + $footer.height() + thisScroll;

					if (thisScroll > '150') {
						$pagetop.addClass(v);
					} else {
						$pagetop.removeClass(v);
					}
					// if(footerPos <= pagetopPos) {
					// 	$pagetop.addClass(f);
					// } else {
					// 	$pagetop.removeClass(f);
					// }
				});
			}
		},
		setModal: function setModal() {
			var createModal = function createModal(_appendHTML) {
				var $container = document.createElement('div');
				$container.className = 'mod-modal-overlay';
				$container.innerHTML = _appendHTML;
				document.body.appendChild($container);

				$container.classList.add(a);
				setTimeout(function () {
					$container.classList.add(v);
					document.querySelectorAll('.mod-modal-closeArea, .mod-modal-closeBtn, .mod-modalContent a').forEach(function (ele) {
						ele.addEventListener('click', function (e) {
							$container.classList.remove(v);
							setTimeout(function () {
								$container.classList.remove(a);
								$container.remove();
							}, 400);
						});
					});
				});
			};

			// 画像1個だけのモーダル
			document.querySelectorAll('.mod-modalImg').forEach(function (ele) {
				ele.addEventListener('click', function (e) {
					var appendHTML = '<div class="mod-modal-closeArea"></div>\n\t\t\t\t\t\t<span class="mod-modal-closeBtn"><i class="icon icon-close"></i></span>\n\t\t\t\t\t\t' + e.currentTarget.querySelector('img').outerHTML;
					createModal(appendHTML);
				});
			});
		},
		headerMenu: function headerMenu() {
			var d = 'is-down';
			var $hamburger = $('.header-hamburger');
			var $nav = $('.header-nav');
			var $search = $('.header-search');
			var $searchBtn = $('.header-btns-search');

			if (THERMOS.va.window.width <= 1024) {
				$('.header-hamburger').on('click', function (e) {
					if ($searchBtn.hasClass(a)) {
						$searchBtn.removeClass(a);
						$search.stop().slideUp(slideSpeed);
					}
					$(e.currentTarget).toggleClass(a);
					$nav.stop().slideToggle(slideSpeed);
				});

				$('.js-spheader-toggle').on('click', function (e) {
					e.preventDefault();
					$(e.currentTarget).toggleClass(d);
					$(e.currentTarget).next('.header-nav-inner, .js-pcmenu').stop().slideToggle(slideSpeed);
				});
			}

			// Mars finder サイト内検索
			if (THERMOS.va.isEnglish) {
				$('.mf_finder_searchBox_query_input').attr('placeholder', 'Please enter a keyword');
			} else {
				$('.mf_finder_searchBox_query_input').attr('placeholder', 'キーワードを入力してください');
			}
			$(document).on('click', '.header-btns-search', function () {
				if ($hamburger.hasClass(a)) {
					$hamburger.removeClass(a);
					$nav.stop().slideUp(slideSpeed);
				}

				if ($search.hasClass(a)) {
					$searchBtn.removeClass(a);
					$search.removeClass(v);
					setTimeout(function () {
						$search.removeClass(a);
					}, 400);
				} else {
					$searchBtn.addClass(a);
					$search.addClass(a);
					setTimeout(function () {
						$search.addClass(v);
					});
				}
			});
			$(document).on('click', '.header-search-close', function () {
				$searchBtn.removeClass(a);
				$search.removeClass(v);
				setTimeout(function () {
					$search.removeClass(a);
				}, 200);
			});
		},
		headerCurrent: function headerCurrent() {
			$('.header-nav-head').each(function (idx, ele) {
				if (THERMOS.va.pathname.indexOf($(ele).attr('href')) !== -1) {
					$(ele).addClass(a);
				}
			});
		},
		headerPcMenuToggle: function headerPcMenuToggle() {
			if (THERMOS.va.window.width > 1024) {
				$('.header-nav-item-head').on('mouseover', function (e) {
					if ($(e.currentTarget).next('.js-pcmenu').length) {
						$('.js-pcmenu').removeClass(a);
						$('.js-pcmenu-toggle').removeClass(a);
						$(e.currentTarget).next('.js-pcmenu').addClass(a);
						!$(e.currentTarget).addClass(a);
					} else {
						$('.js-pcmenu').removeClass(a);
						$('.js-pcmenu-toggle').removeClass(a);
					}
				});
				$('.header-nav').on('mouseleave', function () {
					$('.js-pcmenu').removeClass(a);
					$('.js-pcmenu-toggle').removeClass(a);
				});
				// $(document).on('mouseover', (e) => {
				// 	if (!$(e.target).closest('.js-pcmenu-toggle, .js-pcmenu').length) {
				// 		$('.js-pcmenu').removeClass(a);
				// 		$('.js-pcmenu-toggle').removeClass(a);
				// 	}
				// });
			}
		},
		setProdSearch: function setProdSearch() {
			var $prodSearchForm = $('.prod-searchForm');
			var $formElement = $('#prodSearch');
			var MIN_H = THERMOS.va.window.width >= THERMOS.va.device.sp ? 110 : 90;

			var adjustHeight = function adjustHeight() {
				if (THERMOS.va.pathname === '/product/' || THERMOS.va.pathname === '/product/index.html') {
					var H = $prodSearchForm.find('.__inner').get(0).scrollHeight;
					if ($prodSearchForm.find('.__body').height() + MIN_H <= H) {
						H = $prodSearchForm.find('.__body').height() + MIN_H;
					}
					$prodSearchForm.find('.__inner').css('height', H + 'px');
				} else {
					setTimeout(function () {
						$prodSearchForm.find('.__body').css('height', $prodSearchForm.find('.__body_inner').get(0).scrollHeight + 'px');
					}, 10);
				}
			};

			var addParam = function addParam() {
				var thisSelect = $('select[name=category_select]').val();
				var addHTML = '';
				var typeFlg = false;
				if (thisSelect === 'bottle' || thisSelect === 'lunchbox' || thisSelect === 'kitchen' || thisSelect === 'mug_tumbler' || thisSelect === 'cooler') {
					// 親カテゴリのあるものは中カテゴリが選択されているかチェック
					if ($('input:radio[name="p_category"]:checked').val()) {
						if ($('input:radio[name="p_category"]:checked').val() === 'fryingpan-fixed_fryingpan') {
							// フライパンの場合さらに種類の絞り込みをチェック？
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_multipan">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_fryingpan">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_pot_pan">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_egg">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_set">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_fryingpan">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_pot_pan">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_handle">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_lid">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_lid">';
							addHTML += '<input type="hidden" name="p_category" value="fryingpan-egg_lid">';
						}
					} else {
						// 選択がなければ含まれている中カテゴリーをすべて追加
						switch (thisSelect) {
							case 'bottle':
								addHTML += '<input type="hidden" name="p_category" value="mug">';
								addHTML += '<input type="hidden" name="p_category" value="sport_jug">';
								addHTML += '<input type="hidden" name="p_category" value="straw">';
								addHTML += '<input type="hidden" name="p_category" value="cup">';
								addHTML += '<input type="hidden" name="p_category" value="twoway">';
								addHTML += '<input type="hidden" name="p_category" value="soda">';
								addHTML += '<input type="hidden" name="p_category" value="bottle_accessories">';
								break;
							case 'lunchbox':
								addHTML += '<input type="hidden" name="p_category" value="foodcontainer">';
								addHTML += '<input type="hidden" name="p_category" value="foodcontainer_pouch">';
								addHTML += '<input type="hidden" name="p_category" value="lunchjar">';
								addHTML += '<input type="hidden" name="p_category" value="lunchbox">';
								addHTML += '<input type="hidden" name="p_category" value="cutlery_ladle">';
								break;
							case 'kitchen':
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_fryingpan">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_multipan">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_fryingpan">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_pot_pan">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_egg">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_set">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_fryingpan">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_pot_pan">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_handle">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-fixed_lid">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-detachable_lid">';
								addHTML += '<input type="hidden" name="p_category" value="fryingpan-egg_lid">';
								addHTML += '<input type="hidden" name="p_category" value="shuttlechef">';
								addHTML += '<input type="hidden" name="p_category" value="easy_smoker">';
								addHTML += '<input type="hidden" name="p_category" value="foodstoragecontainer">';
								addHTML += '<input type="hidden" name="p_category" value="kitchen_tools">';
								break;
							case 'mug_tumbler':
								addHTML += '<input type="hidden" name="p_category" value="tumbler">';
								addHTML += '<input type="hidden" name="p_category" value="jug">';
								addHTML += '<input type="hidden" name="p_category" value="mug_cup">';
								addHTML += '<input type="hidden" name="p_category" value="can_holder">';
								addHTML += '<input type="hidden" name="p_category" value="teacup">';
								addHTML += '<input type="hidden" name="p_category" value="tumbler_accessories">';
								addHTML += '<input type="hidden" name="p_category" value="tableware">';
								break;
							case 'bag':
								addHTML += '<input type="hidden" name="p_category" value="cooler">';
								addHTML += '<input type="hidden" name="p_category" value="shopping_bag">';
								addHTML += '<input type="hidden" name="p_category" value="lunch_bag">';
								break;
							default:
								break;
						}
					}
				} else {
					switch (thisSelect) {
						case 'baby':
							addHTML += '<input type="hidden" name="p_category" value="baby">';break;
						case 'pot':
							addHTML += '<input type="hidden" name="p_category" value="pot_icepail">';break;
						case 'coffee_maker':
							addHTML += '<input type="hidden" name="p_category" value="coffee_maker">';break;
						case 'cooler':
							addHTML += '<input type="hidden" name="p_category" value="maintenance">';break;
						case 'outdoor':
							addHTML += '<input type="hidden" name="p_category" value="outdoor">';break;
						case 'mountain':
							addHTML += '<input type="hidden" name="p_category" value="mountain">';break;
						case 'cycling':
							addHTML += '<input type="hidden" name="p_category" value="bicycle_bottle">';break;
						case 'maintenance':
							addHTML += '<input type="hidden" name="p_category" value="maintenance">';break;
						default:
							break;
					}
				}

				return addHTML;
			};

			// ----------------------------------- ▲ FUNCTIONS ▲ --------------------------------------------------------------------

			// カテゴリー選択
			if ($('#prodSearch_category').val() !== '') {
				// 引き継ぎの場合
				$('.__field[data-category=' + $('#prodSearch_category').val() + ']').addClass(a);
			}
			$('#prodSearch_category').on('change', function () {
				// 選択中の項目を全て解除
				$('.__field').each(function (idx, ele) {
					$(ele).removeClass(a);
				});
				$('input[type=radio], input[type=checkbox]').each(function (idx, ele) {
					$(ele).prop('checked', false);
				});

				if ($('#prodSearch_category').val() !== '') {
					$('.__field[data-category=' + $('#prodSearch_category').val() + ']').addClass(a);
				}
				adjustHeight();
			});

			// タイプ選択
			$('input[name="p_category"]').each(function (idx, ele) {
				if ($(ele).prop('checked')) {
					// 引き継ぎの場合
					$('.__field[data-type*=' + $(ele).attr('data-type') + ']').addClass(a);
				}

				$(ele).on('change', function (e) {
					// 配下の選択中の項目を全て解除 ※水筒／タンブラー・マグカップ／バッグカテゴリ以外
					var thisType = $(e.currentTarget).attr('data-type');
					if (thisType !== 'bottle' && thisType !== 'mug_tumbler' && thisType !== 'bag') {
						$('[data-type]').find('input[type=radio], input[type=checkbox]').each(function (idx2, ele2) {
							$(ele2).prop('checked', false);
						});
						$('[data-type]').removeClass(a);
					}
					$('.__field[data-type*=' + $(e.currentTarget).attr('data-type') + ']').addClass(a);

					adjustHeight();
				});
			});

			// 詳細検索フォーム開閉
			if (THERMOS.va.pathname === '/product/' || THERMOS.va.pathname === '/product/index.html') {
				// 製品TOPのみアコーディオン
				$('.__subSwitch').on('click', function (e) {
					$('.__subSwitch').toggleClass('is-open');
					$prodSearchForm.toggleClass('is-open');
					if (!$prodSearchForm.hasClass('is-open')) {
						if (THERMOS.va.window.width >= THERMOS.va.device.sp) {
							$prodSearchForm.find('.__inner').css('height', '250px');
						} else {
							$prodSearchForm.find('.__inner').css('height', '170px');
						}
					} else {
						$prodSearchForm.find('.__inner').css('height', $prodSearchForm.find('.__inner').get(0).scrollHeight + 'px');
					}
				});
			} else {
				// 製品TOP以外はプルダウン（absolute）
				$prodSearchForm.find('.__head').on('click', function (e) {
					$(e.currentTarget).toggleClass('is-open');
					$prodSearchForm.toggleClass('is-open');
					if (!$prodSearchForm.hasClass('is-open')) {
						$prodSearchForm.find('.__body').css('height', '0px');
					} else {
						$prodSearchForm.find('.__body').css('height', $prodSearchForm.find('.__body_inner').get(0).scrollHeight + 'px');
					}
				});
			}

			// 検索ボタン押下
			$prodSearchForm.find('.js-submit').on('click', function () {
				var addHTML = addParam();
				$formElement.append(addHTML);
				$formElement.submit();
			});

			// クリアボタン押下
			$prodSearchForm.find('.js-clear').on('click', function () {
				if ($('.prod-cat').length) {
					var thisCat = THERMOS.va.pathname.split('/')[2].replace(/\.html/, '');
					$('#prodSearch_keyword').val('');
					$prodSearchForm.find('input[type=radio], input[type=checkbox]').each(function (idx2, ele2) {
						$(ele2).prop('checked', false);
					});
					$('.__field').removeClass(a);
					$('.__field[data-category=' + thisCat + ']').addClass(a);
					adjustHeight();
				} else if ($('.prod-series').length) {
					$('#prodSearch_keyword').val('');
					$('.__field[data-type]').find('input[type=radio], input[type=checkbox]').each(function (idx2, ele2) {
						$(ele2).prop('checked', false);
					});
					adjustHeight();
				} else {
					$('#prodSearch_keyword').val('');
					$('#prodSearch_category').val('');
					$('.__field').removeClass(a);
					$prodSearchForm.find('input[type=radio], input[type=checkbox]').each(function (idx2, ele2) {
						$(ele2).prop('checked', false);
					});
					adjustHeight();
				}
			});

			// （PCのみ）フォーム外クリックで閉じる？
			if (THERMOS.va.window.width > THERMOS.va.device.desktop && ($('.prod-cat').length || $('.prod-series').length)) {
				$(document).on('click', function (e) {
					if ($('.prod-searchForm > .__head').hasClass('is-open')) {
						if (!$(e.target).closest('.prod-searchForm').length) {
							$('.prod-searchForm > .__head').removeClass('is-open');
						}
					}
				});
			}

			// ＜初期表示＞カテゴリTOPの検索はカテゴリ固定
			if ($('.prod-cat').length) {
				var thisCat = THERMOS.va.pathname.split('/')[2].replace(/\.html/, '');
				$('#prodSearch_category').val(thisCat);
				$('.__field[data-category=' + thisCat + ']').addClass(a);
			}

			// ＜初期表示＞シリーズ一覧ページの検索はカテゴリ・タイプ固定
			if ($('.prod-series').length) {
				var _thisCat = void 0;
				if ($('.mod-head01-cat').length) {
					_thisCat = $('.mod-head01-cat').attr('data-category');
				} else {
					_thisCat = THERMOS.va.pathname.split('/')[3].replace(/\.html/, '');
				}
				$('#prodSearch_category').val(_thisCat);
				$('.__field[data-category=' + _thisCat + ']').addClass(a);

				if ($('.mod-head01-cat').length) {
					var thisType = THERMOS.va.pathname.split('/')[3].replace(/\.html/, '');
					$('input[name=p_category][id=type-' + thisType + ']').prop('checked', true);

					if (_thisCat === 'bottle') {
						$('.__field[data-type=bottle]').addClass(a);
					} else if (_thisCat === 'mug_tumbler' && thisType !== 'tableware') {
						$('.__field[data-type=mug_tumbler]').addClass(a);
					} else if (_thisCat === 'bag') {
						$('.__field[data-type=bag]').addClass(a);
					} else {
						$('.__field[data-type=' + thisType + ']').addClass(a);
					}
				}
			}
		},
		siteSearch: function siteSearch() {
			var URL = THERMOS.va.isEnglish ? 'www.thermos.jp/english' : 'www.thermos.jp';
			$('.js-siteSearch-submit').on('click', function (e) {
				if ($(e.currentTarget).prev('.js-siteSearch-input').val()) {
					window.open('https://www.google.co.jp/search?sitesearch=' + URL + '&q=' + encodeURIComponent($(e.currentTarget).prev('.js-siteSearch-input').val()), '_blank', 'location,status,menubar,toolbar,scrollbars,resizable,noopener');
				} else {
					window.open('https://www.google.co.jp/search?sitesearch=${URL}', '_blank', 'location,status,menubar,toolbar,scrollbars,resizable,noopener');
				}
			});
			$('.js-siteSearch-input').on('keydown', function (e) {
				if (e.keyCode === 13) {
					if ($(e.currentTarget).val()) {
						window.open('https://www.google.co.jp/search?sitesearch=' + URL + '&q=' + encodeURIComponent($(e.currentTarget).val()), '_blank', 'location,status,menubar,toolbar,scrollbars,resizable,noopener');
					} else {
						window.open('https://www.google.co.jp/search?sitesearch=${URL}', '_blank', 'location,status,menubar,toolbar,scrollbars,resizable,noopener');
					}
				}
			});
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.setModal();
			_this.headerMenu();
			_this.headerCurrent();
			_this.headerPcMenuToggle();
			_this.siteSearch();

			if ($('.prod-searchForm').length) {
				_this.setProdSearch();
			}
		}
	};

	$(function () {
		return THERMOS.localDecision() ? THERMOS.localLoading() : THERMOS.loadDelayScript();
	});
})(window.jQuery);