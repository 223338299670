'use strict';

(function ($, THERMOS, cssua) {
	var a = 'is-active';
	var slideSpeed = '300';

	var noContextMenu = function noContextMenu() {
		$('.js-noContext, .mod-image').each(function (idx, ele) {
			$(ele).on('contextmenu', function () {
				return false;
			});
		});
	};
	var addNewIcon = function addNewIcon() {
		var currentDate = new Date();
		var newIcon = '<span class="mod-new">NEW</span>';

		$('.js-checkDate').each(function (idx, ele) {
			var targetDate = new Date($(ele).attr('datetime'));
			var diff = currentDate - targetDate;
			var diffDay = diff / 86400000;

			if (diffDay < 30) {
				$(ele).prepend(newIcon);
			}
		});
	};
	var infoYearGenerator = function infoYearGenerator() {
		if (!THERMOS.va.isMobile) {
			$('.info-selectYear').hover(function (e) {
				$(e.currentTarget).addClass(a);
				$(e.currentTarget).find('.info-selectYear-body').stop().slideDown(slideSpeed);
			}, function (e) {
				$(e.currentTarget).removeClass(a);
				$(e.currentTarget).find('.info-selectYear-body').stop().slideUp(slideSpeed);
			});
		} else {
			$('.info-selectYear-head').on('click', function (e) {
				$(e.currentTarget).toggleClass(a);
				$(e.currentTarget).next('.info-selectYear-body').stop().slideToggle(slideSpeed);
			});
		}

		var $body = $('.info-selectYear-body');
		var $latestY = $('.info-selectYear_latest');
		var en = THERMOS.va.isEnglish;
		if ($body.length > 0 && $latestY.length > 0) {
			var startY = 2013;
			var latestY = Number($latestY.text());
			var addText = en ? '' : '年';
			var html = '';

			for (var i = latestY; i >= startY; i--) {
				if (i !== 2014 && i !== 2015) {
					html += '<li><a href="/info/' + i + '/">' + i + addText + '</a></li>';
				}
			}
			$body.append(html);
		}
	};
	var trimTopicpath = function trimTopicpath() {
		if (THERMOS.va.pathname.indexOf('/detail/') !== -1) {
			var $path = $('.mod-topicPath-li:last-child').find('.mod-topicPath-span');
			var rawText = $path.text();
			var cnt = 70;
			if (rawText.length > cnt) {
				$path.text(rawText.substring(0, cnt) + '...');
			}
		}
	};
	var trimNewsListTitle = function trimNewsListTitle() {
		if ($('.mod-newsList-title')[0]) {
			$('.mod-newsList-title').each(function (idx, ele) {
				var rawText = $(ele).text();
				var cnt = 70;
				if (rawText.length > cnt) {
					$(ele).text(rawText.substring(0, cnt) + '...');
				}
			});
		}
	};
	var setShareLinks = function setShareLinks() {
		if ($('.info-detail-share')[0]) {
			var shareBtnHTML = '<a href="https://www.facebook.com/sharer/sharer.php?u=#link"' + ' target="_blank" class="mod-share facebook js-share-item">' + '<i class="icon icon-facebook"></i><span>シェア</span></a>' + '<a href="https://twitter.com/share?url=#link&related=twitterapi%2Ctwitter&text=#text"' + ' target="_blank" class="mod-share twitter js-share-item">' + '<i class="icon icon-twitter"></i><span>Tweet</span></a>' + '<a href="http://line.me/R/msg/text/?#text#link" target="_blank" class="mod-share line js-share-item">' + '<i class="icon icon-line"></i><span>LINEで送る</span>';

			$('.info-detail-share').append(shareBtnHTML);
		}

		$('.js-share-item').each(function (idx, ele) {
			var url = encodeURIComponent(document.URL);
			var title = encodeURIComponent(document.title);
			var $this = $(ele);
			var href = $this.attr('href').replace(/#link/ig, url).replace(/#text/ig, title);

			$this.attr('href', href);
		});
	};
	var historyProductsCurrent = function historyProductsCurrent() {
		$('.company-history-products-menu-a').each(function () {
			var $this = $(this);
			var href = $(this).attr('href');

			if (THERMOS.va.pathname.indexOf(href) !== -1) {
				$this.addClass(a);
			}
		});
	};

	noContextMenu();
	addNewIcon();
	trimTopicpath();
	trimNewsListTitle();
	setShareLinks();
	historyProductsCurrent();

	if (THERMOS.va.pathname.indexOf('/info/') !== -1) {
		infoYearGenerator();
	}
})(window.jQuery, window.THERMOS, window.cssua);